/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import root from "window-or-global";
import "../../style/index.scss"



const Layout = ({ title, children }) => {
  const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `);

  return (
    <>
      <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=b5a0e077-c6da-4ea0-bfde-9c7b77ffccd8"> </script>
      <script src="https://secure.ewaypayments.com/scripts/eCrypt.min.js" />
      <script defer src="https://www.googletagmanager.com/gtag/js?id=G-CQQ82QZ60L"></script>
      <script defer type="text/javascript" dangerouslySetInnerHTML={{
        __html: `window.dataLayer = window.dataLayer || [];
 function gtag(){dataLayer.push(arguments);}
 gtag('js', new Date());
 gtag('config', 'G-CQQ82QZ60L');`}} />
      <script type="application/ld+json" dangerouslySetInnerHTML={{
        __html: `{
           "@context": "https://schema.org",
           "@type": "Corporation",
           "name": "GetGud",
           "url": "https://getgud.gg",
           "logo": "https://getgud.gg/static/logo.svg",
           "contactPoint": [{
             "@type": "ContactPoint",
             "telephone": "+61-2-7253-4400",
             "contactType": "sales",
             "areaServed": ["AU","MY","SG","PH","US","GB"],
             "availableLanguage": "en"
           },{
             "@type": "ContactPoint",
             "telephone": "+61-2-7253-4400",
             "contactType": "technical support",
             "areaServed": "AU",
             "availableLanguage": "en"
           }],
           "sameAs": []
         }`
      }} />
      {children}
      <script defer type="text/javascript" dangerouslySetInnerHTML={{
        __html: `window.zESettings = {
          "webWidget": {
              helpCenter: {
                title: {
                  "*": "GetGud Internet"
                }
              },
              "chat": {
                  "concierge": {
                      "name": "GetGud Internet",
                      "title": {
                          "*": "GetGud Internet"
                      }
                  },
                  "title": {
                      "*": "GetGud Internet"
                  },
                  "tags": [
                      "getgud",
                      " q2"
                  ]
              },
              "color": {
                  "theme": "#f1421a",
                  "launcher": "#f1421a",
                  "launcherText": "#ffffff",
                  "headerText": "#ffffff",
                  "button": "#f1421a",
                  "resultLists": "#f1421a",
                  "header": "#f1421a",
                  "articleLinks": "#f1421a"
              },
              "answerBot": {
                  "suppress": true,
                  "title": {
                    "*": "GetGud Internet"
                  },
                  "avatar": {
                      "name": {
                          "*": "GetGud Bot"
                      }
                  }
              },
              "contactForm": {
                  "tags": [
                      "getgud",
                      " q2"
                  ]
              },
              "contactOptions": {
                  "enabled": true
              },
              "talk": {
                "title": {
                  "*": "GetGud Internet"
                }
              }
          }
      }`
      }} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
